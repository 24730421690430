import { BladeProvider } from '@razorpay/blade/components';
import { bladeTheme } from '@razorpay/blade/tokens';
import React from 'react';
import OtpModal, { OtpModalProps } from './OtpModal';

const OtpModalWidget = (
  props: OtpModalProps & {
    data: {
      resendAction: string;
      actionFieldName: string;
      actionFieldValue: string;
    };
  },
) => {
  const createOrReuseActionField = (form: HTMLFormElement, replacementActionFielValue?: string) => {
    // Replace the value of hidden input with the action value only if it exists, otherwise create the field and add to the form
    const actionFieldName = props.data.actionFieldName;
    const actionField = form.querySelector(`input[name="${actionFieldName}"]`) as HTMLInputElement;
    if (actionField) {
      if (replacementActionFielValue) {
        // For Resend action need to replace the action with - with-otp
        actionField.value = replacementActionFielValue;
      }
    } else {
      // Create the field and add to the form
      const newActionField = document.createElement('input');
      newActionField.type = 'hidden';
      newActionField.name = actionFieldName;
      newActionField.value = props.data.actionFieldValue;
      form.appendChild(newActionField);
    }
  };

  // function to get the closest form and call the submit of that form
  const onSubmit = (e: React.MouseEvent) => {
    const form = e.currentTarget.closest('form');
    if (form) {
      createOrReuseActionField(form, props.data.actionFieldValue);
      HTMLFormElement.prototype.submit.call(form);
    }
  };

  const onResend = (e: React.MouseEvent) => {
    e.preventDefault();
    const form = e.currentTarget.closest('form');
    if (form) {
      createOrReuseActionField(form, props.data.resendAction);
      HTMLFormElement.prototype.submit.call(form);
    }
  };

  return (
    <BladeProvider themeTokens={bladeTheme} colorScheme="dark">
      <OtpModal onVerify={onSubmit} onResend={onResend} {...props} />
    </BladeProvider>
  );
};

export default OtpModalWidget;
